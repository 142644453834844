.App {
  text-align: center;
  }
  
  .card {
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
  }
  
  .card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  }
  
  .featured img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  }
  
  .menu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  }
  
  .input-text {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f1f1f1;
  color: #333;
  }
  
  .nav-link {
  display: inline-block;
  box-sizing: border-box;
  padding: 10px 20px;
  margin: 0 6px;
  background-color: #eb1b1b;
  text-decoration: none;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  }
  
  .nav-link:hover {
  background-color: #eb1b1b;
  }
  
  .active {
  background-color: #eb1b1b;
  }

  .search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px
  }
  
  